import React from 'react'

import { Link, Typography } from '@mui/material'

const OrderClientIDCell = ({ handleClick, client_id, ...props }) =>
  <>
    <Typography sx={{ color: 'primary', fontWeight: 600, fontSize: 'subtitle1.fontSize' }}>
      <Link sx={{ color: 'inherit' }} target='_blank' onClick={handleClick}>
        {client_id}
      </Link>
    </Typography>
  </>


export default OrderClientIDCell

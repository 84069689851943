
import React from 'react'

const withContainer = Component => ({ row, path, record_id, ...props }) => {

  const handleClick = useCallback(() => {
    window.open(_.join(_.compact([path, record_id]), '/'), '_blank')
    e.stopPropagation()
  }, [path, record_id])


  const mergedProps = {
    handleClick,
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer

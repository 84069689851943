import React from 'react'

import Box from '@mui/material/Box'
import { ButtonComponent } from '@front/squirtle'

const ButtonHeader = ({
  fields,
  column: {
    disableOnCreate = false,
    disableOnDetail = false,
    disableOnEdit = false,
  },
  stateProps: {
    isReadOnly,
    buttonList
  },
  ...props
}, context) => {

  const createMode = !!disableOnCreate ? false : isReadOnly
  const editMode = !!disableOnDetail ? true : isReadOnly

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, width: 1, height: 1 }}>
      <Box>
        <ButtonComponent
          disabled={(!editMode && !createMode && !disableOnEdit)}
          size='small'
          options={buttonList}
        />
      </Box>
    </Box>
  )
}

export default ButtonHeader
